// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);

@import "normalize";

@import "prettyCheckable";

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  background-color: #f0f0f0;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #222;
  line-height: 150%;
}
a {
  color: #222;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.topsitemenu {
  background: #fff;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 2.8125rem
}
.topsitemenu a {
  text-decoration: none
}
.topsitemenu .topnavigation-wrapper.show {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 150ms linear;
  -moz-transition: opacity 150ms linear;
  transition: opacity 150ms linear
}
.topsitemenu .topnavigation-wrapper.hidden {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s 300ms, opacity 300ms linear;
  -moz-transition: visibility 0s 300ms, opacity 300ms linear;
  transition: visibility 0s 300ms, opacity 300ms linear
}
.topsitemenu #topNavigationItems {
  float: left
}
.topsitemenu #topNavigationSelector {
  float: right
}
.topsitemenu #topNavigationSelector .selectLanguageText {
  vertical-align: top;
  display: inline-block;
  font-size: 13px
}
.topsitemenu #topNavigationSelector .selectLanguageSelect {
  vertical-align: top;
  display: inline-block;
  margin: 0 .625rem 0 .625rem
}
.topsitemenu #topNavigationSelector .selectLanguageSelect select {
  border: 1px solid #dedede;
  min-width: 10.625rem;
  font-size: 13px;
  height: 1.375rem;
  line-height: 1.375rem
}
.topsitemenu .topnavigation-wrapper {
  height: 2.8125rem;
  line-height: 2.8125rem;
  width: 100%;
  border-bottom: 1px solid #dedede;
  padding: 0 .75rem;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s 150ms, opacity 150ms linear;
  -moz-transition: visibility 0s 150ms, opacity 150ms linear;
  transition: visibility 0s 150ms, opacity 150ms linear
}
.topsitemenu .topnavigation-wrapper ul {
  margin: 0;
  padding: 0
}
.topsitemenu .topnavigation-wrapper ul li {
  list-style: none;
  display: inline-block;
  cursor: pointer
}
.topsitemenu .topnavigation-wrapper ul li .topNavigationButton {
  height: 2.8125rem;
  padding: 1px .8125rem 0 .8125rem;
  position: relative
}
.topsitemenu .topnavigation-wrapper ul li .topNavigationButton.full-width input[type="button"],
.topsitemenu .topnavigation-wrapper ul li .topNavigationButton.full-width input[type="submit"] {
  width: 100%
}
.topsitemenu .topnavigation-wrapper ul li input[type="button"],
.topsitemenu .topnavigation-wrapper ul li input[type="submit"] {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0 .9375rem 0 0;
  color: #222;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  height: 100%;
  width: auto;
  top: 0;
  text-align: right;
  left: 0;
  position: absolute;
  outline: none;
  -webkit-transition: all 350ms linear;
  -moz-transition: all 350ms linear;
  transition: all 350ms linear
}
.topsitemenu .topnavigation-wrapper ul li input[type="button"]:hover,
.topsitemenu .topnavigation-wrapper ul li input[type="submit"]:hover {
  outline: none
}
.topsitemenu .topnavigation-wrapper ul li a {
  display: block;
  padding: 0 .8125rem;
  font-weight: 400;
  -webkit-transition: all 350ms linear;
  -moz-transition: all 350ms linear;
  transition: all 350ms linear
}
.topsitemenu .topnavigation-wrapper ul li a:focus {
  color: #222
}
.topsitemenu .topnavigation-wrapper ul li svg {
  fill: #222;
  stroke: #222;
  width: .75rem;
  height: .75rem;
  display: inline-block;
  margin: 0 .3125rem 0 0;
  -webkit-transition: all 350ms linear;
  -moz-transition: all 350ms linear;
  transition: all 350ms linear
}
.topsitemenu .topnavigation-wrapper ul li:hover svg {
  fill: #08c;
  stroke: #08c
}
.topsitemenu .topnavigation-wrapper ul li:hover a,
.topsitemenu .topnavigation-wrapper ul li:hover input[type="button"],
.topsitemenu .topnavigation-wrapper ul li:hover input[type="submit"] {
  color: #08c
}
.topsitemenu .topnavigation-wrapper ul li#mailSelectTopNavigation,
.topsitemenu .topnavigation-wrapper ul li#printSelectTopNavigation {
  cursor: default;
  margin: 0 .8125rem
}
.topsitemenu .topnavigation-wrapper ul li#mailSelectTopNavigation select,
.topsitemenu .topnavigation-wrapper ul li#printSelectTopNavigation select {
  border: 1px solid #dedede;
  font-size: 13px;
  height: 1.375rem;
  line-height: 1.375rem;
  cursor: pointer
}
.topsitemenu .topnavigation-wrapper ul li.ToolbarMainButtonDivDisabled,
.topsitemenu .topnavigation-wrapper ul li.menuItemDisabled {
  opacity: 0.22;
  cursor: default
}
.topsitemenu .topnavigation-wrapper ul li.ToolbarMainButtonDivDisabled a,
.topsitemenu .topnavigation-wrapper ul li.menuItemDisabled a {
  color: #222 !important
}
.topsitemenu .topnavigation-wrapper ul li.ToolbarMainButtonDivDisabled svg,
.topsitemenu .topnavigation-wrapper ul li.menuItemDisabled svg {
  fill: #222 !important;
  stroke: #222 !important
}
.topsitemenu .topnavigation-wrapper ul li.ToolbarMainButtonDivDisabled input[type="button"],
.topsitemenu .topnavigation-wrapper ul li.ToolbarMainButtonDivDisabled input[type="submit"],
.topsitemenu .topnavigation-wrapper ul li.menuItemDisabled input[type="button"],
.topsitemenu .topnavigation-wrapper ul li.menuItemDisabled input[type="submit"] {
  cursor: default;
  color: #222 !important
}
.topsitemenu .topnavigation-wrapper ul li .shape-publishNavigationhighLighted {
  fill: #5BC560
}
.topsitemenu .topnavigation-wrapper ul li .publishNavigationhighLighted {
  color: #5BC560 !important
}
.topsitemenu .topnavigation-wrapper ul li .publishNavigationhighLighted:hover {
  color: #08c !important
}
.panelWrapper {
  margin: 0 20px 0 20px;
  padding: 20px 0 30px 0;
}
.panelWrapper table {
  width: 100%;
  font-size: 13px;
  font-weight: 300;
}
.panelWrapper tr table {
  margin: 0 0 10px 0;
}
.panelWrapper tr table table {
  margin: 0;
}
.subPanelHeader {
  background: #fff;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  padding-left: 10px;
  line-height: 40px;
  border: 1px solid #dedede;
  margin: 0;
}
.subPanelContent {
  padding: 17px 10px 20px 10px;
  background: #fff;
  margin: 0 10px 0 0;
  position: relative;
  border: 1px solid #dedede;
  border-top: none
}

input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, textarea:focus, input[type="color"]:active, input[type="date"]:active, input[type="datetime"]:active, input[type="datetime-local"]:active, input[type="email"]:active, input[type="month"]:active, input[type="number"]:active, input[type="password"]:active, input[type="search"]:active, input[type="tel"]:active, input[type="text"]:active, input[type="time"]:active, input[type="url"]:active, input[type="week"]:active, textarea:active {
  border-top: 1px solid #CACACA;
  border-bottom: 1px solid #CACACA;
  border-left: 5px solid #08c;
  border-right: 1px solid #CACACA;
  padding: 0 10px 0 6px !important;
  outline: none;
}

input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, textarea:hover, input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, textarea:focus {
  box-shadow: inset 0px 2px 2px #eee,1px 1px 0 #fff;
}

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea {
  display: block;
  border: 1px solid #CACACA;
  //height: 2.1875rem;
  line-height: 2.1875rem;
  width: 100%;
  max-width: 25rem;
  border-radius: 3px;
  padding: 0 .625rem;
  box-shadow: inset 2px 2px 2px #eee, 1px 1px 0 #fff;
  font-size: 13px;
  font-weight: 300;
  -webkit-transition: border-color 200ms linear;
  -moz-transition: border-color 200ms linear;
  transition: border-color 200ms linear;
}
.select2-container--default .select2-selection--single {
  border-radius: 0;
}
.fauxbutton {
  height: 45px;
  padding: 15px 8px;
  cursor: pointer;
}
.tabs {
  overflow: hidden;
  padding-bottom: 50px; /* Needs to be equal to the top value for tab-content */
  border: 0;
}

.tabs li {
  display: inline;
}

.tabs li label {
  height: 34px;
  display: inline-block;
  margin: 0;
  padding: 0 18px;
  line-height: 32px;
  border: 1px solid #dedede;
  background-color: #FFF;
}

.tabs li input[type="radio"] {
  display: none;
}

.tabs li input[type="radio"]:checked ~ .tab-content {
  display: block;
}

.tabs li input[type="radio"]:checked ~ label {
  background-color: #000;
  color: #FFF;
}

.tabs li .tab-content {
  width: 100%;
  float: left;
  margin-left: -100%;
  padding: 20px;
  position: relative;
  left: 100%;
  top: 34px;
  display: none;
}

.button,input[type="button"] {
  width:auto;
  min-height:2.375rem;
  line-height:2.375rem;
  padding:0 .9375rem;
  font-size:1em;
  background:#08c;
  color:#fff;
  -webkit-border-radius:3px;
  -moz-border-radius:3px;
  border-radius:3px;
  border:none;
  background:#08c;
  display:inline-block;
  font-weight:500;
  text-align:center;
  -webkit-transition:background-color,200ms,linear;
  -moz-transition:background-color,200ms,linear;
  transition:background-color,200ms,linear
}
.button a,input[type="button"] a {
  font-size:1em;
  margin:0 -.9375rem;
  padding:0 .9375rem;
  display:block;
  width:auto;
  height:100%
}
.button,.button a,input[type="button"],input[type="button"] a {
  color:#fff !important
}
.button:hover,.button:focus,input[type="button"]:hover,input[type="button"]:focus {
  background:#00699e
}
.button,.button a,input[type="button"],input[type="button"] a {
  text-decoration:none !important
}
.button.small,input[type="button"].small {
  width:auto;
  min-height:1.875rem;
  line-height:1.875rem;
  padding:0 .625rem;
  font-size:.75rem
}
.button.small a,input[type="button"].small a {
  font-size:.75rem;
  margin:0 -.625rem;
  padding:0 .625rem;
  display:block;
  width:auto;
  height:100%
}
.button.medium,input[type="button"].medium {
  width:auto;
  min-height:2.5rem;
  line-height:2.5rem;
  padding:0 1.25rem;
  font-size:.875rem
}
.button.medium a,input[type="button"].medium a {
  font-size:.875rem;
  margin:0 -1.25rem;
  padding:0 1.25rem;
  display:block;
  width:auto;
  height:100%
}
.button.large,input[type="button"].large {
  width:auto;
  min-height:3.125rem;
  line-height:3.125rem;
  padding:0 1.875rem;
  font-size:1rem
}
.button.large a,input[type="button"].large a {
  font-size:1rem;
  margin:0 -1.875rem;
  padding:0 1.875rem;
  display:block;
  width:auto;
  height:100%
}
.button.fluid,input[type="button"].fluid {
  width:100%;
  min-height:2.8125rem;
  line-height:2.8125rem;
  padding:0 1.875rem;
  font-size:1.125rem
}
.button.fluid a,input[type="button"].fluid a {
  font-size:1.125rem;
  margin:0 -1.875rem;
  padding:0 1.875rem;
  display:block;
  width:auto;
  height:100%
}
.button>[class*="iconic-"],input[type="button"]>[class*="iconic-"] {
  line-height:inherit !important
}
.button.disable,.button.disable>a,input[type="button"].disable,input[type="button"].disable>a {
  pointer-events:none;
  opacity:0.5;
  cursor:default
}
.button.warning,input[type="button"].warning {
  background:#ffbf11;
  color:#8f6f19
}
.button.warning,.button.warning a,input[type="button"].warning,input[type="button"].warning a {
  color:#8f6f19 !important
}
.button.warning a:hover,input[type="button"].warning a:hover {
  color:#382c0a !important
}
.button.warning:hover,.button.warning:focus,input[type="button"].warning:hover,input[type="button"].warning:focus {
  background:#e2a500;
  color:#382c0a !important
}
.button.info,input[type="button"].info {
  background:#2489c5;
  color:#fff
}
.button.info,.button.info a,input[type="button"].info,input[type="button"].info a {
  color:#fff !important
}
.button.info:hover,.button.info:focus,input[type="button"].info:hover,input[type="button"].info:focus {
  background:#1d6e9e
}
.button.success,input[type="button"].success {
  background:#00cc82;
  color:#fff
}
.button.success,.button.success a,input[type="button"].success,input[type="button"].success a {
  color:#fff !important
}
.button.success:hover,.button.success:focus,input[type="button"].success:hover,input[type="button"].success:focus {
  background:#009e65
}
.button.unknown,input[type="button"].unknown {
  background:#232323;
  color:#ebebeb
}
.button.unknown,.button.unknown a,input[type="button"].unknown,input[type="button"].unknown a {
  color:#ebebeb !important
}
.button.unknown a:hover,input[type="button"].unknown a:hover {
  color:#b8b8b8 !important
}
.button.unknown:hover,.button.unknown:focus,input[type="button"].unknown:hover,input[type="button"].unknown:focus {
  background:#0c0c0c;
  color:#b8b8b8 !important
}
.button.alert,input[type="button"].alert {
  background:red;
  color:#fff
}
.button.alert,.button.alert a,input[type="button"].alert,input[type="button"].alert a {
  color:#fff !important
}
.button.alert:hover,.button.alert:focus,input[type="button"].alert:hover,input[type="button"].alert:focus {
  background:#d10000
}